$(document).foundation();

// Logout
$('.menu-top').on('click', '#logout,.button-logout', (e) => {
  e.preventDefault();
  $.ajax({
    url: '/api/login',
    type: 'DELETE',
    success: function(data, textStatus, request){
      var returnTo = request.getResponseHeader('return-to');
      if(returnTo) {
        window.location.href = "/admin/home/" + returnTo;
      } else {
        window.location.reload();
      }
    },
    error: function (request, textStatus, errorThrown) {
      window.location.reload();
    }
  });
});

// Duplicated and jQueryified logic of hiding/showing the menubar
let menuIsOpen = false;
let headerIsShowing = true;
let scrollTop = 0;
const getHeight = (el) => {
  if (el === document) {
    return Math.max(
      el.body.scrollHeight, el.documentElement.scrollHeight,
      el.body.offsetHeight, el.documentElement.offsetHeight,
      el.body.clientHeight, el.documentElement.clientHeight
    );
  }
  return window.innerHeight
  || document.documentElement.clientHeight
  || document.body.clientHeight;
};

const scrollEventHandler = () => {
  const currentScrollTop = window.pageYOffset || document.documentElement.scrollTop;

  if (!menuIsOpen && currentScrollTop > 70 && currentScrollTop < (getHeight(document) - getHeight(window))) {

    if (currentScrollTop > scrollTop) {
      // Downscroll
      headerIsShowing = false;
      $(".header, .admin-impersonated-usermenu").removeClass("showHeader").addClass("hideHeader");
    } else if (!headerIsShowing) {
      // Upscroll
      headerIsShowing = true;
      $(".header, .admin-impersonated-usermenu").addClass("showHeader").removeClass("hideHeader");
    }
    scrollTop = currentScrollTop;
  }
};

$(window).on('scroll', scrollEventHandler.bind(window));

// FAQ
$(document).on('click', '.toggleable-textbox .toggleable-textbox-title', (e) => {
  e.preventDefault();
  let toggler = e.currentTarget.children[0].children[0];
  $(e.currentTarget).siblings('.toggleable-textbox-content').slideToggle('normal', () => null );
  if(toggler.innerHTML === '+') {
    toggler.innerHTML = '-';
  }else {
    toggler.innerHTML = '+';
  }
});

// Product-list toggle text
$(document).on('click', '.read-more, .read-less', (event) => {
  event.preventDefault();
  $(event.target).closest(".product-text").toggleClass("expanded");
});

// Product lightbox
$(document).on('click', '.product-link', (event) => {
  event.preventDefault();
  var $modal = $('#modal');
  $.get(event.currentTarget.href, { modal: true })
  .done(function(resp){
    $modal.html(resp).foundation('open');
  });
});

// Update localeStorage locale when using locale-switcher,
$(document).on('click', '.locale-switcher a', (event) => {
  localStorage.setItem('locale', $(event.currentTarget).data('locale'));
});
